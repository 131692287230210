import { ALL_LOB } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

export default defineNuxtRouteMiddleware(async (to) => {
  if (!process.client) {
    return
  }

  const workspace = useWorkspace()

  if (
    workspace.underMaintenance &&
    !guard(
      'ANY',
      [PERMISSIONS.readSystem, PERMISSIONS.writeSystem],
      workspace.userPermissions,
    )
  ) {
    return navigateTo({ path: '/account' }, { replace: true })
  }

  const connId = to.query.lob as string
  if (connId && connId !== ALL_LOB) {
    workspace.previousConnectorId = connId
  }

  workspace.setActiveConnector(ALL_LOB)
})
